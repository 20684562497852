// Utils.js

export function isValidNumber(value) {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue) && isFinite(parsedValue);
  }
  
  export function sanitizeString(input) {
    const div = document.createElement("div");
    div.textContent = input;
    return div.innerHTML;
  }
  