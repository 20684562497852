import React, { useState, useRef } from "react";
// Import the sanitize utility
import emailjs from "@emailjs/browser";
import { sanitizeString } from "../utils/utils";

const FeedbackForm = () => {
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs
      .sendForm(
        "service_i0tjjkk",
        "template_elak73m",
        form.current,
        "NDZFJzfGYwutvbdwZ"
      )
      .then(
        (result) => {
          // Removed console.log for cleanliness
          setSubmitStatus("success");
          setMessage("");
        },
        (error) => {
          // Removed console.log for cleanliness
          setSubmitStatus("error");
        }
      )
      .finally(() => {
        setIsSubmitting(false);
        setTimeout(() => {
          setIsFormVisible(false);
          setSubmitStatus(null);
        }, 3000);
      });
  };

  const toggleForm = () => {
    setIsFormVisible(!isFormVisible);
    setSubmitStatus(null);
  };

  return (
    <div className="feedback-container">
      <button
        onClick={toggleForm}
        className="feedback-toggle"
        aria-label="Toggle feedback form"
      >
        {isFormVisible ? "×" : "?"}
      </button>
      {isFormVisible && (
        <div className="feedback-form">
          <h2>Provide Feedback</h2>
          {submitStatus === "success" ? (
            <p>Thank you for your feedback!</p>
          ) : submitStatus === "error" ? (
            <p>There was an error sending your feedback. Please try again.</p>
          ) : (
            <form ref={form} onSubmit={sendEmail}>
              <textarea
                name="message"
                value={message}
                // Sanitize user input to help avoid XSS
                onChange={(e) => setMessage(sanitizeString(e.target.value))}
                placeholder="Enter your feedback or suggestions here"
                required
              ></textarea>
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Sending..." : "Submit Feedback"}
              </button>
            </form>
          )}
        </div>
      )}
    </div>
  );
};

export default FeedbackForm;
